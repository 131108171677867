<template>
  <v-container class="ma-0 pa-0" style="height: 100%; width: 98%">
    <v-card
      width="98%"
      elevation="0"
      rounded="lg"
      height="100%"
      class="ma-0 pa-2"
      align="left"
      v-if="!isCategory"
      :loading="loading"
    >
      <div :style="isMobile ? 'padding:0px 15px 0px 15px; ' : 'padding: 15px;'">
        <div v-if="!isBinary">
          <p style="margin-top: 15px; display: inline-block; width: 100%">
            <span style="max-width: 55%; float: left"
              >{{ entityName }} {{ factorCode }}</span
            >

            <span>{{ encodeCategoricalFactors(individualFactor) }}</span>
          </p>
          <v-divider></v-divider>
          <p style="margin-top: 15px; display: inline-block; width: 100%">
            <span style="max-width: 55%; float: left"
              >Average {{ factorCode }} for all banks</span
            >
            <span>{{ encodeCategoricalFactors(peerGroupFactor) }}</span>
          </p>
          <v-divider></v-divider>
        </div>
        <p style="margin-top: 15px; display: inline-block; width: 100%">
          <span style="max-width: 55%; float: left"
            >{{ getText }} {{ factorCode }}</span
          >
          <span style="float: right">
            <v-icon small :class="getColor"> mdi-triangle </v-icon>
            {{ getvalueFromWaterfall }}
          </span>
        </p>
        <br />
        <v-divider v-if="isBinary"></v-divider>
        <p style="margin-top: 10px"></p>
        <p style="margin-top: 10px">
          {{ factorCode }} = {{ factorDesc }}{{ factorText }}
        </p>
      </div>
      <template slot="progress">
        <v-overlay
          absolute
          class="d-flex flex-column text-center"
          color="white"
          opacity="0.9"
        >
          <div>
            <v-progress-circular
              size="23"
              :width="5"
              color="#3057b8"
              indeterminate
            >
            </v-progress-circular>
            <span style="margin-left: 10px">loading</span>
          </div>
        </v-overlay>
      </template>
    </v-card>
    <v-card
      rounded="lg"
      elevation="0"
      width="100%"
      height="100%"
      class="ma-0 pa-2"
      align="left"
      :loading="loading"
      v-else
    >
      <div :style="isMobile ? 'padding:0px 15px 0px 15px; ' : 'padding: 15px;'">
        <p style="margin-top: 30px; display: inline-block; width: 100%">
          <span style="max-width: 55%; float: left"
            >{{ entityName }} {{ getText }} {{ factorDesc }} factors</span
          >
          <span style="float: right">
            <v-icon small :class="getColor"> mdi-triangle </v-icon>
            {{ getvalueFromWaterfall }}
          </span>
        </p>
        <v-divider></v-divider>
        <p style="margin-top: 10px"></p>
        <p style="margin-top: 10px">{{ factorDesc }} = {{ factorText }}</p>
      </div>
      <template slot="progress">
        <v-overlay
          absolute
          class="d-flex flex-column text-center"
          color="white"
          opacity="0.9"
        >
          <div>
            <v-progress-circular
              size="23"
              :width="5"
              color="#3057b8"
              indeterminate
            >
            </v-progress-circular>
            <span style="margin-left: 10px">loading</span>
          </div>
        </v-overlay>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["selectedFactor", "waterfallData"],
  data() {
    return {
      waterFallCategory: this.waterfallData,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      displayType: "displayType",
      currentCurrency: "currentCurrency",
    }),
    factorText() {
      if (this.selectedFactor) {
        return this.selectedFactor.text;
      } else {
        return "";
      }
    },
    factorDesc() {
      if (this.selectedFactor) {
        return this.selectedFactor.description;
      } else {
        return "";
      }
    },
    factorCode() {
      if (this.selectedFactor) {
        return this.selectedFactor.code;
      } else {
        return "";
      }
    },
    entityName() {
      if (this.currentEntity) {
        return this.currentEntity.entityCode;
      } else {
        return "";
      }
    },
    textDisplayType() {
      if (this.displayType === "marketValue") {
        return this.currentCurrency.text + " ";
      } else if (this.displayType === "multiple") {
        return " x";
      } else {
        return " %";
      }
    },

    getvalueFromWaterfall() {
      let factor = 0;
      //console.log(this.getFactorstring(this.selectedFactor))
      if (this.selectedFactor && this.waterFallCategory) {
        factor =
          this.waterFallCategory[
            this.getFactorstring(this.selectedFactor).replace(/-/, " ")
          ];
      }
      //todo adapt current displayType
      if (factor) {
        if (this.displayType === "marketValue") {
          return (
            this.textDisplayType +
            Math.round(factor)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            " million"
          );
        } else if (this.displayType === "multiple") {
          return factor.toFixed(2) + this.textDisplayType;
        } else {
          return factor.toFixed(1) + this.textDisplayType;
        }
      }
      return 0;
    },
    isCategory() {
      //console.log(this.selectedFactor);
      ////console.log(this.waterfallData);
      // Show different Table for Categories, and for the three factors which are derived from the model and have no source data
      if (this.selectedFactor) {
        if (
          ["Company", "Financial", "Market", "Economic"].includes(
            this.selectedFactor.description
          )
        ) {
          return true;
        }
      }
      return false;
    },
    isBinary() {
      //console.log(this.selectedFactor);
      ////console.log(this.waterfallData);
      // Show different Table for Categories, and for the three factors which are derived from the model and have no source data
      if (this.selectedFactor) {
        if (
          ["Company Specific Effect", "Time Effect", "Country Effect"].includes(
            this.selectedFactor.description
          )
        ) {
          return true;
        }
      }
      return false;
    },

    individualFactor() {
      const sourceData = this.$store.getters.getSourceDataEntity;
      //console.log(this.selectedFactor);
      if (
        sourceData &&
        this.selectedFactor &&
        sourceData[this.selectedFactor.id]
      ) {
        return Number(sourceData[this.selectedFactor.id].replace(/,/, "."));
      } else {
        return 0;
      }
    },
    peerGroupFactor() {
      const sourceData = this.$store.getters.getSourceDataPeer;
      let sum = 0;
      //console.log(sourceData);
      if(this.selectedFactor){
      if (this.selectedFactor.id === "R13") {
        let counter = 0;
        for (let i = 0; i < sourceData.length; i++) {
          let factor = sourceData[i][this.selectedFactor.id];
          if (factor && factor != 0) {
            sum += Number(factor.replace(/,/, "."));
            counter++;
          }
        }
        return (sum / counter).toFixed(2);
      } else {
        for (let i = 0; i < sourceData.length; i++) {
          let factor = sourceData[i][this.selectedFactor.id];

          if (factor) {
            sum += Number(factor.replace(/,/, "."));
          }
        }
        return (sum / sourceData.length).toFixed(2);
      }
    }
    return 0;
    },
    currentEntity() {
      return this.$store.getters.getEntity;
    },
    getFactorValue() {
      let factor = 0;

      if (this.selectedFactor && this.waterFallCategory) {
        factor =
          this.waterFallCategory[
            this.selectedFactor.description.replace(/-/, " ")
          ];
      }
      //console.log(factor)
      return factor;
    },
    getColor() {
      if (this.getFactorValue >= 0.0) {
        return "positive";
      } else {
        return "negative";
      }
    },
    getText() {
      if (this.getFactorValue >= 0) {
        return "Valuation premium from ";
      } else {
        return "Valuation discount from ";
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
  },
  methods: {
    getFactorstring(factor) {
      if (this.isMobile) {
        return factor.mobileCode;
      }
      return factor.description;
    },
    encodeCategoricalFactors(value) {
      if (this.selectedFactor && !this.isCategory) {
        if (this.selectedFactor.id === "R13") {
          let encoding = this.$store.getters.esgEncoding;
          //console.log(value);
          return encoding.find((el) => el.value === Math.round(value)).short;
        } else if (this.selectedFactor.id === "R28") {
          let encoding = this.$store.getters.msciEncoding;
          return encoding.find((el) => el.value === Math.round(value)).name;
        } else if (this.selectedFactor.id === "R09") {
          let encoding = this.$store.getters.creditratingEncoding;
          //console.log(value);
          return encoding.find((el) => el.value === Math.round(value)).short;
        } else if (this.selectedFactor.id === "R10") {
          let encoding = this.$store.getters.ratingWatch;
          return encoding.find((el) => el.value === Math.round(value)).name;
        } else if (this.selectedFactor.id === "R29") {
          let encoding = this.$store.getters.irQualityEncoding;
          return encoding.find((el) => el.value === Math.round(value)).name;
        } else if (this.selectedFactor.id === "R11") {
          return (
            "USD " +
            Math.round(value)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            " million"
          );
        } else if (this.selectedFactor.id === "R42") {
          return "USD " + Number(value).toFixed(1);
        } else if (
          this.selectedFactor.id === "R04" ||
          this.selectedFactor.id === "R05"
        ) {
          return (value * 100).toFixed(1) + "%";
        } else if (
          this.selectedFactor.id === "R01" ||
          this.selectedFactor.id == "R02" ||
          this.selectedFactor.id == "R03" ||
          this.selectedFactor.id == "R07" ||
          this.selectedFactor.id == "R36"
        ) {
          return (value * 100).toFixed(2) + "%";
        } else if (this.selectedFactor.id === "R21") {
          //console.log(value)
          return Math.round(value);
        }else if (this.selectedFactor.id ==="R44") 
          return value
        else {
          ////console.log(value)
          return Math.round(value * 100) + "%";
        }
      }
      return "";
    },
  },
  watch: {
    waterfallData: {
      immediate: true,
      deep: true,
      handler() {
        this.waterFallCategory = this.waterfallData;
      },
    },
    "$store.getters.isLoadingPred": {
      immediate: true,
      deep: true,
      handler(loading) {
        //console.log("loading" + loading);
        //console.log(loading);
        //console.log("loadingChart" + loading);

        this.loading = loading;
      },
    },
  },
};
</script>

<style scoped>
span {
  float: right;
  margin-right: 5px;
}
.positive {
  margin-bottom: 4px;
  color: #5ab7b7;
}
.negative {
  color: red;
  margin-bottom: 3px;
  transform: rotate(180deg);
}
@media (max-width: 960px) {
  p {
    margin-top: 2px !important;
    margin-bottom: 5px !important;
  }
}
</style>